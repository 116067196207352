<template>
	<table>
		<tr>
      		<td :colspan="treeData.children ? treeData.children.length * 2 : 1" 
			  	:class="{ parentLevel: treeData.children, 
				  		  extend: treeData.children && treeData.children.length > 0 && treeData.extend,
				  		  'condition-margin': treeData.condition }">
				<div :class="{ node: true }">
					<div v-if="treeData.type == 'start'" class="placeholder-loader" style="height: 60px; width: 60px; border-radius: 50%"></div>
					<div  v-if="treeData.type == 'procedure'" class="placeholder-loader" style="height: 88px; width: 180px; margin-top: 30px; border-radius: 10px"></div>
				</div>
				<div class="extend-handle-tbf" :class="{open: !parentOpen}" v-if="treeData.children && treeData.children.length > 0"></div>
			</td>
		</tr>
		<tr v-if="treeData.children && treeData.extend" class="children-row-wrapper">
			<td v-for="(children, index) in treeData.children" :key="index" colspan="2" class="childLevel">
				<ProcessChartLoader :json="children" />
			</td>
		</tr>
	</table>
</template>

<script>
export default {
	data() {
		return {
			treeData: {},
			parentOpen: false,
		};
	},
  	props: {
  		json: Object
  	},
  	name: "ProcessChartLoader",
	components: {
	
	},
	watch: {
		json: {
			handler: function(Props) {
				let extendKey = function(jsonData) {
					var jsonExtend = [];

					if(jsonExtend.find(el => el.unique_id == jsonData.unique_id)){
						jsonData.extend = jsonExtend.find(el => el.unique_id == jsonData.unique_id).extend
					}else{
						jsonData.extend = true
						jsonExtend.push({ id: jsonData.unique_id, extend: jsonData.extend })
					}

					return jsonData;
				};
				if(Props) {
					if(typeof Props === 'object'){
						this.treeData = extendKey(Props);
					}
				}
			},
			immediate: true,
		},
	},
	mounted(){
		
	},
  	methods: {
		
  	}
};
</script>
