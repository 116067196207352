<template>
	<div>
		<div>
			<div class="tree-page decision-tree">
				<div class="header-tree">
					<div class="tree-actions">
						<div class="left-part">
							<div class="filter-version dropdown">
								 <div class="placeholder-loader" style="height: 40px; width: 80px;"></div>
							</div>

							<div class="btn-make-live">
								 <div class="placeholder-loader" style="height: 40px; width: 40px;"></div>
							</div>
						</div>

						<div class="center-part">
                            <div v-if="$resize && $mq.above(600)" class="placeholder-loader" style="height: 20px; width: 100px;"></div>
						</div>

						<div class="right-part">
							<div v-if="$resize && $mq.above(780)" class="placeholder-loader" style="height: 40px; width: 80px; margin-right: 10px"></div>
                            <div class="placeholder-loader" style="height: 40px; width: 40px; margin-right: 10px"></div>
                            <div class="placeholder-loader" style="height: 40px; width: 40px;"></div>
						</div>
					</div>
				</div>

				<div class="scrollable-chart">
					<div class="center-box" id="divToImage">
						<ProcessChartLoader class="organigram-tbf loader" :json="Object.keys(processJson).length ? processJson : {}" :style="'transform: scale(' + zoomPage + ');' " />
					</div>
				</div>
			</div>
		</div>
		
	</div>
</template>


<script type="text/javascript">
    import ProcessChartLoader from '../PagesLoaders/ProcessChartLoader'
	export default {
		data() {
			return {
                zoomPage: 1,
				processJson: {"id":1, "type":"start", "children":[{"id":1, "type":"procedure"}]},
			};
		},
		components: {
            ProcessChartLoader
		},
		async mounted() {
		},
		methods: {
			centerTree(){
				var calcWidth = $('.scrollable-chart .center-box .parentLevel .node').position().left - ($(window).width() / 2) + (($('.scrollable-chart .center-box .parentLevel .node').width() / 2))

				$('.scrollable-chart').animate({
					scrollTop: $('.scrollable-chart').offset().top - $(window).height()/2,
					scrollLeft: calcWidth
				}, 300);

			},
		}
	};
</script>